import React, { createContext, useState, useContext, useEffect } from 'react';

interface AuthContextType {
  isLoggedIn: boolean;
  user: { user_id: string; user_name: string } | null;
  login: (userInfo: { user_id: string; user_name: string }) => boolean;
  logout: () => void;
  isPasswordProtected: boolean;
  authenticatePassword: (password: string) => boolean;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(() => {
    return JSON.parse(localStorage.getItem('isLoggedIn') || 'false');
  });
  const [user, setUser] = useState<{ user_id: string; user_name: string } | null>(() => {
    return JSON.parse(localStorage.getItem('user') || 'null');
  });
  const [isPasswordProtected, setIsPasswordProtected] = useState<boolean>(true);

  useEffect(() => {
    localStorage.setItem('isLoggedIn', JSON.stringify(isLoggedIn));
    localStorage.setItem('user', JSON.stringify(user));
  }, [isLoggedIn, user]);

  const login = (userInfo: { user_id: string; user_name: string }): boolean => {
    setIsLoggedIn(true);
    setUser(userInfo);
    return true;
  };

  const logout = () => {
    setIsLoggedIn(false);
    setUser(null);
    localStorage.removeItem('isLoggedIn');
    localStorage.removeItem('user');
  };

  const authenticatePassword = (password: string): boolean => {
    const correctPassword = 'yeschef';
    const sanitizedPassword = password.trim().toLowerCase().replace(/[^\w]/g, '');
    const authenticated = sanitizedPassword === correctPassword;
    
    if (authenticated) {
      setIsPasswordProtected(false);
      localStorage.setItem('isPasswordProtected', 'false');
    }
    return authenticated;
  };

  useEffect(() => {
    const storedProtection = localStorage.getItem('isPasswordProtected');
    if (storedProtection !== null) {
      setIsPasswordProtected(JSON.parse(storedProtection));
    }
  }, []);

  return (
    <AuthContext.Provider value={{ isLoggedIn, user, login, logout, isPasswordProtected, authenticatePassword }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};