import React, { createContext, useContext, useState, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';

interface SessionContextType {
  sessionId: string;
  resetSession: () => void;
}

const SessionContext = createContext<SessionContextType | undefined>(undefined);

export const SessionProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [sessionId, setSessionId] = useState<string>(() => {
    // Try to get existing session ID from sessionStorage, or create a new one
    const existingSession = sessionStorage.getItem('session_uuid');
    if (existingSession) return existingSession;
    
    const newSession = uuidv4();
    sessionStorage.setItem('session_uuid', newSession);
    return newSession;
  });

  const resetSession = () => {
    const newSession = uuidv4();
    sessionStorage.setItem('session_uuid', newSession);
    setSessionId(newSession);
  };

  return (
    <SessionContext.Provider value={{ sessionId, resetSession }}>
      {children}
    </SessionContext.Provider>
  );
};

export const useSession = () => {
  const context = useContext(SessionContext);
  if (context === undefined) {
    throw new Error('useSession must be used within a SessionProvider');
  }
  return context;
}; 