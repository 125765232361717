import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation } from 'react-router-dom';
import HeaderWrapper from './components/HeaderWrapper';
import Footer from './components/Footer';
import Home from './pages/Home';
import About from './pages/About';
import Login from './pages/Login';
import RecipeSuggestions from './pages/RecipeSuggestions';
import PasswordProtection from './components/PasswordProtection';
import Contact from './pages/Contact';
import Faq from './pages/Faq';
import KitchenTips from './pages/KitchenTips';
import RecipesHistory from './pages/RecipesHistory';
import { AuthProvider, useAuth } from './context/AuthContext';
import './styles/App.css';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsOfService from './pages/TermsOfService';
import { SessionProvider } from './context/SessionContext';
import SharedRecipe from './pages/SharedRecipe';

const App: React.FC = () => {
  return (
    <AuthProvider>
      <SessionProvider>
        <Router>
          <MainApp />
        </Router>
      </SessionProvider>
    </AuthProvider>
  );
}

const MainApp: React.FC = () => {
  const { isPasswordProtected } = useAuth();
  const location = useLocation();
  const currentPath = location.pathname;

  console.log('Current path:', currentPath);
  
  // Skip password protection for any recipe UUID URLs
  const isSharedRecipeUrl = /^\/recipes\/[a-f0-9-]+$/.test(currentPath);
  
  console.log('Is shared recipe URL:', isSharedRecipeUrl);
  
  // Only show password protection for non-recipe URLs
  if (isPasswordProtected && !isSharedRecipeUrl && currentPath !== '/password-protection') {
    console.log('Redirecting to password protection');
    return <Navigate 
      to="/password-protection" 
      state={{ from: currentPath }} 
      replace 
    />;
  }

  // For password protection page, render only the PasswordProtection component
  if (currentPath === '/password-protection') {
    console.log('Showing password protection');
    return <PasswordProtection />;
  }

  // For all other routes, render the main app layout
  console.log('Rendering main app');
  return (
    <div className="app-container">
      <div className="App">
        <HeaderWrapper />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/recipes" element={<RecipeSuggestions />} />
          <Route path="/recipes/:uuid" element={<SharedRecipe />} />
          <Route path="/about" element={<About />} />
          <Route path="/kitchen_tips" element={<KitchenTips />} />
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<Login />} />
          <Route path="/account" element={<Login />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/faq" element={<Faq />} />
          <Route path="/privacy" element={<PrivacyPolicy />} />
          <Route path="/terms" element={<TermsOfService />} />
          <Route path="*" element={<Navigate to="/" state={{ from: location.pathname }} />} />
        </Routes>
        <Footer />
      </div>
    </div>
  );
}

export default App;