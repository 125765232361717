import React, { useState, useEffect } from 'react';
import IngredientInput from '../components/IngredientInput';
import RecipeList from '../components/RecipeList';
import { useApi, Recipe } from '../services/api';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import "../styles/buttons.css";

const RecipeSuggestions: React.FC = () => {
  const api = useApi();
  const [recipes, setRecipes] = useState<Recipe[] | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [apiCalled, setApiCalled] = useState<boolean>(false);
  const [apiSuccess, setApiSuccess] = useState<boolean>(false);
  const [currentTextIndex, setCurrentTextIndex] = useState<number>(0);
  const [buttonsVisible, setButtonsVisible] = useState<boolean>(false);
  const [customizeVisible, setCustomizeVisible] = useState<boolean>(false);
  const [recipeCount, setRecipeCount] = useState<number>(0);
  const [sessionUUID, setSessionUUID] = useState<string | null>(null);
  const [responseUserId, setResponseUserId] = useState<string | null>(null);
  const [recipeId, setRecipeId] = useState<string | null>(null);
  const navigate = useNavigate();
  const { isLoggedIn, user } = useAuth();

  const handleIngredientSubmit = async (ingredients: string[], cuisine: string, dietaryRestrictions: string[]) => {
    console.log("Submitting ingredients:", ingredients);
    setLoading(true);
    setError(null);
    setApiCalled(true);
  
    const isVegan = dietaryRestrictions.includes('Vegan');
    const isVegetarian = dietaryRestrictions.includes('Vegetarian');
    const isGlutenFree = dietaryRestrictions.includes('Gluten-Free');
    const isDairyFree = dietaryRestrictions.includes('Dairy-Free');
  
    try {
      console.log("Calling getRecipeSuggestions");
      const { recipes: fetchedRecipes, session_uuid, responseUserId, recipe_id } = await api.getRecipeSuggestions({
        ingredients,
        cuisine,
        is_vegan: isVegan,
        is_vegetarian: isVegetarian,
        is_gluten_free: isGlutenFree,
        is_dairy_free: isDairyFree,
        user_id: user?.user_name
      });
      console.log("Received recipes:", fetchedRecipes);
      setRecipes(fetchedRecipes);
      setSessionUUID(session_uuid);
      setResponseUserId(responseUserId);
      setRecipeId(recipe_id);
      setApiSuccess(true);

      if (!isLoggedIn) {
        setRecipeCount(prevCount => prevCount + 1);

        if (recipeCount + 1 >= 4) {
          navigate('/login', { state: { message: 'Please log in to generate more recipes.' } });
        }
      }
    } catch (err) {
      console.error('Error getting recipe suggestions:', err);
      setError('Error fetching recipes. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleBackClick = () => {
    setRecipes(null);
    setApiSuccess(false);
    setApiCalled(false);
  };

  const handleCloseLoading = () => {
    setLoading(false);
  };

  useEffect(() => {
    if (loading) {
      const interval = setInterval(() => {
        // First fade out current text
        const spans = document.querySelectorAll('.loading-text span');
        spans[currentTextIndex].classList.remove('fade-in');
        spans[currentTextIndex].classList.add('fade-out');
  
        // Wait for fade out, then fade in next text
        setTimeout(() => {
          setCurrentTextIndex((prevIndex) => {
            const nextIndex = (prevIndex + 1) % 3;
            // Remove fade-out and add fade-in to next text
            spans[prevIndex].classList.remove('fade-out');
            spans[nextIndex].classList.add('fade-in');
            return nextIndex;
          });
        }, 500); // Half a second for fade out
  
      }, 3000); // Total cycle time
  
      return () => clearInterval(interval);
    }
  }, [loading, currentTextIndex]);

  useEffect(() => {
    if (recipes) {
      setButtonsVisible(true);
    } else {
      setButtonsVisible(false);
    }
  }, [recipes]);

  const toggleCustomizeContainer = () => {
    setCustomizeVisible(!customizeVisible);
  };

  const handleShareRecipe = (recipeId: string) => {
    const shareLink = `${window.location.origin}/recipes/${recipeId}`;
    navigator.clipboard.writeText(shareLink).then(() => {
      alert('Recipe link copied to clipboard!');
    }).catch(err => {
      console.error('Failed to copy the link: ', err);
    });
  };

  return (
    <div className="recipe-suggestions">
      {!apiSuccess && (
        <div className="input-section">
          <h1 className="find-recipes">Find Recipes</h1>
          <p className="enter-ingredients">Enter at least three ingredients below to get recipes with ingredients you already have on hand. </p>
          <div className="divider"></div>
          <IngredientInput onSubmit={handleIngredientSubmit} />
        </div>
      )}
      {loading && (
        <div className="loading-overlay">
          <div className="loading-popup">
            <button className="close-button" onClick={handleCloseLoading}>×</button>
            <p>Loading recipes...</p>
            <video autoPlay loop muted playsInline style={{ maxWidth: '300px', width: '300px' }}>
              <source src="/rat_stir_looped.mp4" type="video/mp4" />
              Your browser does not support the video tag.
            </video>
            <div className="loading-text">
              {['Coming up with recipe ideas', "Translating your grandmother's cookbook",'Consulting with top chefs'].map((text, index) => (
                <span key={index} className={index === currentTextIndex ? 'fade-in' : ''}>
                  {text}
                </span>
              ))}
            </div>
          </div>
        </div>
      )}
      {error && <div className="error">{error}</div>}
      {recipes && (
        <div>
          <RecipeList recipes={recipes} />
          <div className="expand-button-wrapper">
          <div className={`expand-button-container ${buttonsVisible ? 'active' : ''}`}>
            <button className="button back-button" onClick={handleBackClick}>Back</button>
            <button
              className="button share-recipe-button"
              onClick={() => {
                if (recipeId) {
                  handleShareRecipe(recipeId);
                } else {
                  console.error('Session UUID is null');
                  alert('Unable to share recipes. Please try again.');
                }
              }}
            >
              Share Recipes
            </button>
          </div>
          <div className={`customize-recipe-container ${customizeVisible ? 'active' : ''}`}>
              {/* Customize recipe content */}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default RecipeSuggestions;