import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import { GoogleLogin, GoogleOAuthProvider } from '@react-oauth/google';
import { CredentialResponse } from '@react-oauth/google';
import { useApi } from '../services/api';
import { useAuth } from '../context/AuthContext';
import '../styles/Login.css';



const Login: React.FC = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [userName, setUserName] = useState('');
  const [isLogin, setIsLogin] = useState(true);
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  const { login, logout, user, isLoggedIn } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const api = useApi();

  useEffect(() => {
    if (!isLoggedIn && location.state?.message) {
      setError(location.state.message);
    }
  }, [isLoggedIn, location.state]);

  interface GoogleCredential {
    name?: string;
    given_name?: string;
    email: string;
  }
  
  const handleGoogleLoginSuccess = async (credentialResponse: CredentialResponse) => {
    if (!credentialResponse.credential) return;
    
    try {
      const decoded = jwtDecode<GoogleCredential>(credentialResponse.credential);
      const googleUserName = decoded.name || decoded.given_name || 'GoogleUser';
      const email = decoded.email;
  
      const response = await api.signupUser(email, credentialResponse.credential, googleUserName, 'en', 'google');
      
      if (response.authentication_status) {
        login({ user_id: response.user_id ?? '', user_name: googleUserName });
        setSuccessMessage('You have successfully logged in with Google');
        setTimeout(() => navigate('/'), 2000);
      } else {
        throw new Error('Signup failed');
      }
    } catch (error) {
      console.error('Error processing Google login:', error);
      setError('Failed to process Google login. Please try again.');
    }
  };

  const handleAppleLogin = () => {
    // window.AppleID.auth.signIn();
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError('');
    setSuccessMessage('');
    try {
      if (isLogin) {
        const loginSuccess = login({ user_id: email, user_name: email });
        if (loginSuccess) {
          setSuccessMessage('You have successfully logged in');
          setTimeout(() => {
            navigate('/recipes');
          }, 2500);
        } else {
          setError('Login failed. Please check your credentials.');
        }
      } else {
        const response = await api.signupUser(email, password, userName, 'en', 'email');
        if (response.authentication_status) {
          setIsLogin(true);
          setError('Signup successful! Please log in.');
        } else {
          setError('Signup failed. Please try again.');
        }
      }
    } catch (error) {
      if (error instanceof Error) {
        setError(error.message || 'Authentication failed. Please try again.');
      } else {
        setError('An unexpected error occurred. Please try again.');
      }
      console.error('Authentication error:', error);
    }
  };

  return (
    <div className="login-container">
      <div className="login-header">
        <h2 className="title">Account</h2>
      </div>
      <p className="about-description">Welcome chef, we are excited to join you in the kitchen</p>

      <div className="password-protection-box-container">
        <div className="password-protection-box">
          {isLoggedIn ? (
            <div className="login-title-container">
              <p>Welcome, {user?.user_name}</p>
              <button className="reset-password-button">Reset Password</button>
              <button onClick={logout} className="sign-out-button">
                Sign Out
              </button>
            </div>
          ) : (
            <>
              <div className="login-title-container"> 
                <p className="login-title">{isLogin ? 'Login' : 'Sign Up'}</p>
                <hr className="title-divider" />
              </div>
              {error && (
                <div className="error-message" style={{ color: 'red', marginBottom: '10px' }}>
                  {error}
                </div>
              )}
              <form onSubmit={handleSubmit}>
                {!isLogin && (
                  <input
                    type="text"
                    className="form-input"
                    value={userName}
                    onChange={(e) => setUserName(e.target.value)}
                    placeholder="Chef Name"
                    required
                  />
                )}
                <input
                  type="email"
                  className="form-input"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Email"
                  required
                />
                <input
                  type="password"
                  className="form-input"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="Password"
                  required
                />
                <div className="google-login-wrapper">
                  <GoogleOAuthProvider clientId="824106840830-tpimve4gfhtg2i9g54446iqth8s4gh07.apps.googleusercontent.com">
                    <GoogleLogin
                      onSuccess={handleGoogleLoginSuccess}
                      onError={() => {
                        console.error('Google Login Failed');
                        setError('Google login failed. Please try again.');
                      }}
                      type="standard"
                      theme="outline"
                      size="large"
                      text="signin_with"
                      shape="rectangular"
                      width="100%"
                      locale="en"
                      useOneTap={false}
                    />
                  </GoogleOAuthProvider>
                </div>
                <button type="submit" className="submit-button">
                  {isLogin ? 'Login' : 'Sign Up'}
                </button>
              </form>
              <div className="login-switch-text-container">
                <p className="login-switch-text">
                  {isLogin ? "Don't have an account? " : "Already have an account? "}
                  <a href="#" onClick={(e) => {
                    e.preventDefault();
                    setIsLogin(!isLogin);
                    setError('');
                  }}>
                    {isLogin ? 'Sign Up' : 'Login'}
                  </a>
                </p>
              </div>
              {successMessage && (
              <div className="success-message" style={{ color: 'green', marginBottom: '10px' }}>
                {successMessage}
              </div>
              )}
            </>
          )}
        </div>
      </div>
      <div className={`about-tomatoes-bottom ${isLoggedIn ? 'signed-in' : isLogin ? 'signed-out-login' : 'signed-out-signup'}`}>
        <img src="/tomato_stems.png" alt="Tomato" className="tomato-icon" />
      </div>
    </div>
  );
};

export default Login;