import React, { useState, useRef, useEffect } from 'react';
import { Recipe } from '../services/api';
import { animated, useSpring } from 'react-spring';
import '../styles/todays_menu.css'; // Import the new CSS file for the title
import '../styles/buttons.css'; // Import the button styles

interface RecipeListProps {
  recipes: Recipe[];
}

const RecipeList: React.FC<RecipeListProps> = ({ recipes }) => {
  const [expandedIndex, setExpandedIndex] = useState<number | null>(null);
  const recipeRefs = useRef<(HTMLLIElement | null)[]>([]);

  const toggleExpand = (index: number) => {
    setExpandedIndex(expandedIndex === index ? null : index);
  };

  const animationProps = useSpring({
    config: { tension: 300, friction: 20 },
  });

  useEffect(() => {
    if (expandedIndex !== null) {
      const element = recipeRefs.current[expandedIndex];
      if (element) {
        setTimeout(() => {
          element.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
        }, 300); // Delay to allow expansion animation to start
      }
    }
  }, [expandedIndex]);

  return (
    <div className="recipe-list">
      <h2 className="todays-menu-title">Today's Menu</h2>
      <div className="todays-menu-divider"></div>
      <ol className="recipe-list-ol">
        {recipes.map((recipe, index) => {
          const isExpanded = expandedIndex === index;

          return (
            <li 
              key={index} 
              className="recipe-card"
              ref={el => recipeRefs.current[index] = el}
            >
              <div className="recipe-header" onClick={() => toggleExpand(index)}>
                <span className="recipe-number">{index + 1}</span>
                <div className="recipe-info">
                  <h3 className="recipe-title">{recipe.meal}</h3>
                  <div className="recipe-ingredients">
                    <em>{recipe.ingredients.join(', ')}</em>
                  </div>
                </div>
                <animated.div
                  style={{ 
                    transform: isExpanded ? 'rotate(45deg)' : 'rotate(0deg)',
                    transition: 'transform 0.3s ease'
                  }}
                  className="toggle-icon"
                >
                  +
                </animated.div>
              </div>
              <animated.div
                style={{
                  ...animationProps,
                  opacity: isExpanded ? 1 : 0,
                  maxHeight: isExpanded ? 1000 : 0,
                  overflow: 'hidden',
                  transition: 'opacity 0.3s ease, max-height 0.3s ease'
                }}
              >
                <div className="recipe-details">
                  <div className="instructions">
                    <strong>Instructions:</strong> {recipe.instructions}
                  </div>
                  <br />
                  <div className="cuisine">Cuisine: {recipe.cuisine}</div>
                  <div className="dietary-info">
                    <div>Vegan: <span className={recipe.is_vegan ? 'true' : 'false'}>{recipe.is_vegan ? 'True' : 'False'}</span></div>
                    <div>Vegetarian: <span className={recipe.is_vegetarian ? 'true' : 'false'}>{recipe.is_vegetarian ? 'True' : 'False'}</span></div>
                    <div>Gluten Free: <span className={recipe.is_gluten_free ? 'true' : 'false'}>{recipe.is_gluten_free ? 'True' : 'False'}</span></div>
                    <div>Dairy Free: <span className={recipe.is_dairy_free ? 'true' : 'false'}>{recipe.is_dairy_free ? 'True' : 'False'}</span></div>
                  </div>
                  <div className="calories">Calories: {recipe.calories}</div>
                  <div className="cook-time">Cook Time: {recipe.cook_time} minutes</div>

                </div>
              </animated.div>
            </li>
          );
        })}
      </ol>
    </div>
  );
};

export default RecipeList;