import React, { useState, useEffect } from 'react';
import { useAuth } from '../context/AuthContext';
import { useNavigate, useLocation } from 'react-router-dom';
import '../styles/PasswordProtection.css';

interface LocationState {
  from: string;
}

const PasswordProtection: React.FC = () => {
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const { authenticatePassword } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  // Add debug logging on mount
  useEffect(() => {
    console.log('PasswordProtection mounted:', {
      locationState: location.state,
      pathname: location.pathname
    });
  }, [location]);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    console.log('Password submitted, pre-auth state:', {
      locationState: location.state,
      pathname: location.pathname
    });

    const isAuthenticated = authenticatePassword(password);
    console.log('Authentication result:', isAuthenticated);

    if (!isAuthenticated) {
      setError('No chef, incorrect password. Please try again.');
    } else {
      setError('');
      const state = location.state as LocationState;
      const redirectTo = state?.from || '/';
      
      console.log('Authentication successful, redirecting:', {
        redirectTo,
        state
      });

      if (redirectTo === '/password-protection') {
        navigate('/', { replace: true });
      } else {
        navigate(redirectTo, { replace: true });
      }
    }
  };

  return (
    <div className="password-protection-wrapper">
      <div className="password-protection-container">
        <h1 className="bonjour-chef">Bonjour Chef</h1>
        <img src="/shefmeme.png" alt="Chef Meme" className="logo"/>
        <p className="enter-password-start">Are you ready chef? <br/> Enter 'yes chef' to start cooking</p>
        <form onSubmit={handleSubmit}>
          <input
            className="enter-password"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Enter Password"
            autoFocus
          />
          <button className="submit" type="submit">Submit</button>
        </form>
        {error && <p className="error-message">{error}</p>}
      </div>
    </div>
  );
};

export default PasswordProtection;